import React, { useEffect, useState, type FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useStore } from '@nanostores/react';
import classNames from 'classnames';
import { useForm, type SubmitHandler } from 'react-hook-form';
import Cookies from 'js-cookie';

import type { Props, Inputs } from './Form.types';
import styles from './form.module.scss';
import { schema } from './schema';
import { RegisterSvg } from './RegisterSvg';
import { useTranslations } from '../../../../i18n/utils';
import { POLICIES } from '../../../../constants';
import {
  addToGoogleSheet,
  clientDatasStore,
  commonsStore,
  fetchRegister,
  googleSheetStore,
  meStore,
  registerStore,
  showModalsStore,
  fetchMe,
} from '../../../../store';

import { Manager } from '../Manager';

import './form.scss';
import { EyeIcon } from './images/EyeIcon';
import { EyeOffIcon } from './images/EyeOffIcon';

export const Form: FC<Props> = ({ className, lang }) => {
  const $clientDatasStore = useStore(clientDatasStore);
  const $commonsStore = useStore(commonsStore);
  const $registerStore = useStore(registerStore);
  const $googleSheetStore = useStore(googleSheetStore);
  const $meStore = useStore(meStore);
  const { isSuccess: googleSheetSuccess, manager } = $googleSheetStore;
  const { error: registerError } = $registerStore;
  const { error: meError } = $meStore;
  const { error: googleSheetError } = $googleSheetStore;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: zodResolver(schema) });
  const managerCookies = Cookies.get('manager') || '';

  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const t = useTranslations(lang);

  const [checkbox, setCheckbox] = useState(false);

  const handlePassClick = () => {
    setShowPass((prev) => !prev);
  };

  const handleTermsClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    showModalsStore.set([POLICIES.TERMS]);
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { email, password, telegram } = data;
    if ($clientDatasStore && $commonsStore) {
      const { languages, currencies, countries } = $commonsStore;
      const {
        country: { default_currency_code, default_language_code, code },
      } = $clientDatasStore;
      const langId = languages.data.find(
        (item) => item.code === default_language_code,
      )?.id;
      const currencyId = currencies.data.find(
        (item) => item.code === default_currency_code,
      )?.id;
      const countryId = countries.data.find((item) => item.code === code)?.id;
      if (checkbox) {
        setLoading(true);
        let formData = new FormData();
        formData.append('telegram', telegram);
        const obj = {
          email,
          password,
          country_id: countryId,
          language_id: langId,
          currency_id: currencyId,
        };
        const handleGoogleSheet = () => {
          addToGoogleSheet(formData);
        };

        const handleMe = () => {
          fetchMe(handleGoogleSheet);
        };

        fetchRegister(obj, handleMe);
      }
    }
  };

  useEffect(() => {
    if (googleSheetSuccess) {
      setLoading(false);
    }
  }, [googleSheetSuccess]);

  useEffect(() => {
    if (registerError || meError || googleSheetError) {
      setLoading(false);
    }
  }, [registerError, meError, googleSheetError]);

  return (
    <div className={styles.container}>
      {(googleSheetSuccess && manager) ||
      (managerCookies && managerCookies.length > 0) ? (
        <Manager lang={lang} manager={manager || managerCookies} />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classNames(styles.form, className)}
        >
          {registerError && (
            <div className={styles['email-error']}>
              {t('register.email_exist')}
            </div>
          )}
          {(meError || googleSheetError) && (
            <div className={styles['email-error']}>{t('register.error')}</div>
          )}

          <div
            id='register-row'
            className={classNames(styles.row, 'register-row')}
          >
            <div className={styles.box}>
              <label className={styles.label} htmlFor='email'>
                {t('register.form.fields.email')}
              </label>

              <input
                className={styles.input}
                placeholder={t('register.form.fields.email')}
                {...register('email')}
              />
            </div>
            <div className={styles.box}>
              <label className={styles.label} htmlFor='password'>
                {t('register.form.fields.password')}
              </label>
              <div className={styles.password}>
                <input
                  type={showPass ? 'text' : 'password'}
                  className={styles.input}
                  placeholder={t('register.form.fields.password')}
                  {...register('password')}
                />
                <button
                  onClick={handlePassClick}
                  type='button'
                  className={styles.show}
                >
                  {showPass ? <EyeOffIcon /> : <EyeIcon />}
                </button>
              </div>
            </div>
          </div>

          <div
            id='register-box'
            className={classNames(styles.box, 'register-box')}
          >
            <label className={styles.label} htmlFor='telegram'>
              {t('register.form.fields.telegram')}
            </label>
            <input
              className={styles.input}
              placeholder={t('register.form.fields.telegram.placeholder')}
              {...register('telegram')}
            />
          </div>
          <div
            id='register-submit'
            className={classNames(
              styles.row,
              styles['row_extra-margin'],
              'register-submit',
            )}
          >
            <button
              type='button'
              onClick={() => setCheckbox((prev) => !prev)}
              className={classNames(styles['checkbox-container'], {
                [styles['checkbox-container_active']]: checkbox,
              })}
            >
              <span className={styles.decor} />
              <p className={styles.label}>
                {t('register.form.agree.1')}{' '}
                <span onClick={handleTermsClick} className={styles.terms}>
                  {t('register.form.terms_conditions')}
                </span>
              </p>
            </button>
            <button disabled={loading} className={styles.submit} type='submit'>
              <RegisterSvg /> {t('register.form.submit')}
            </button>
          </div>
          <div className={styles.errors}>
            {errors?.email?.message && (
              // @ts-ignore
              <p className={styles.error}>{t(errors.email.message)}</p>
            )}
            {errors?.password?.message && (
              // @ts-ignore
              <p className={styles.error}>{t(errors.password.message)}</p>
            )}
            {errors?.telegram?.message && (
              // @ts-ignore
              <p className={styles.error}>{t(errors.telegram.message)}</p>
            )}
          </div>
        </form>
      )}
    </div>
  );
};
